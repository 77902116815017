import React from "react";
import { MuiBox, MuiIconButton, MuiTooltip, MuiTypography } from "components/MUI";
import { connect, useDispatch } from "react-redux";
import { setLogout } from "redux/Actions/login";
import AuthService from "services/AuthService";
import { NotificationMenu, UserMenu } from "components";
import { setResetCard, setResetPlan } from "redux/Actions/subscription";

function Header(props) {

    const { user, handleDrawerToggle, open } = props;
    const dispatch = useDispatch();
    const rootDomain = process.env.NODE_ENV == 'development' ? 'vockam.bert' : window.location.host
    const shouldShowDomain = !['canceled', 'none'].includes(user?.subscription_status);



    const handleLogout = () => {
        AuthService.logout().then((resp) => {
            if (resp.status === 'success') {
                dispatch(setLogout());
                dispatch(setResetCard());
                dispatch(setResetPlan());
            }
        }).catch((err) => {
            return err;
        })
    }

    const handleRedirectDomain = () => window.open(`${window.location.protocol}//${user?.domain}.${rootDomain}`, '_blank');

    return (
        <MuiBox className='dashHeaderInnerMain'>
            <MuiBox className='headerLeft'>
                
            </MuiBox>
            <MuiBox className='headerRight'>
                {shouldShowDomain && (
                    <MuiTooltip title="Go to url">
                    <MuiIconButton onClick={handleRedirectDomain}>
                    {`${user?.domain}.${rootDomain}`}                               
                    </MuiIconButton>
                </MuiTooltip>
                )}
                <NotificationMenu notifications={user.notifications ? user.notifications : []} />
                <UserMenu user={user} handleLogout={handleLogout} />
            </MuiBox>
        </MuiBox>
    );
}

const mapStateToProps = (state) => ({
    userStatus: state.login.userStatus,
    user: state.login.user
})

export default connect(mapStateToProps)(Header);
