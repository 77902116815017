import React, { useState } from 'react';
import { TextField, InputAdornment } from "@mui/material";
import { MuiBox, MuiIconButton } from 'components/MUI';
import { SearchIcon } from 'helpers/Icons';

const SearchToggle = (props) => {

    const [toggleSearch, setToggleSearch] = useState(false);


    const setToggleFunc = () => {
        setToggleSearch(!toggleSearch)
    };


    return (
        <MuiBox className='searchToggleWrap'>
            <MuiIconButton sx={{ marginRight: '10px' }} onClick={setToggleFunc}>
                <SearchIcon />
            </MuiIconButton>

            {toggleSearch && <MuiBox className='textFieldMainWrap' sx={{
                '& .MuiInputAdornment-positionStart': {
                    margin: '0 12px 0 0',
                },
                '& .MuiOutlinedInput-root': {
                    borderRadius: '5px',
                },
                '& .MuiInputBase-root:hover fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23) !important'
                },
                '& div:hover fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23) !important'
                },
                '& .MuiInputBase-root': {
                    '&.Mui-focused': {
                        '& fieldset': {
                            borderWidth: 1,
                        }
                    }
                },
            }}>
                <TextField
                    {...props}
                    variant="outlined"
                />
            </MuiBox> }
        </MuiBox>
    );
}

export default SearchToggle;
