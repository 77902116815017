import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { NotificationsIcon } from 'helpers/Icons';
import { MuiBadge } from "components/MUI";

const NotificationMenu = ({ notifications }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (notifications.length > 0)
      setAnchorEl(event.currentTarget);
  }
  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <IconButton aria-controls="notification-menu" aria-haspopup="true" onClick={handleClick} color="inherit">
        <MuiBadge badgeContent={notifications.length} color="warning">
          <NotificationsIcon />
        </MuiBadge>
      </IconButton>
      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {notifications.map((notif, notiKey) => <MenuItem key={notiKey} onClick={handleClose}>{notif.data.message}</MenuItem>)}

      </Menu>
    </div>
  );
};


NotificationMenu.propTypes = {
  notifications: PropTypes.array,
};
NotificationMenu.defaultPropTypes = {
  notifications: []
}
export default NotificationMenu;
